<template>
    <div>
      <national-trademark-transaction-report-list></national-trademark-transaction-report-list>
    </div>
  </template>
  <script>
  export default {
  }
  </script>
  
  
  
  